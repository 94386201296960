<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import { tologin } from "./utils/app";

export default {
  methods: {
    clear() {
      window.sessionStorage.clear();
      window.localStorage.clear();
    },
  },
  mounted() {
    // 监听 401 事件
    window.addEventListener(
      "AppLoginEvent",
      () => {
        if (this.$route.path !== "/login") {
          tologin();
        }
      },
      false
    );

    // 监听 403 事件
    window.addEventListener(
      "AppForbiddenEvent",
      () => {
        if (this.$route.path !== "/login") {
          tologin();
        }
      },
      false
    );
  },
};
</script>
<style lang="scss">

@import url("./style/base.scss");
.clear {
  position: fixed;
  width: 60px;
  height: 24px;
  font-size: 12px;
  top: 0;
  z-index: 2000;
}
body,
html {
  margin: 0;
  padding: 0;
}
p {
  margin: 0;
}
li {
  list-style: none;
}
* {
  box-sizing: border-box;
  font-family: PingFang SC, Hiragino Sans GB, Microsoft YaHei, SimSun,
    sans-serif, "Open Sans", -apple-system, BlinkMacSystemFont, "Helvetica Neue",
    Helvetica, Segoe UI, Arial, Roboto, "PingFang SC", "miui",
    "Hiragino Sans GB", "Microsoft Yahei", sans-serif;
}
.van-icon{
  font-family: vant-icon !important;
}
#app {
  font-family: PingFang SC, Hiragino Sans GB, Microsoft YaHei, SimSun,
    sans-serif, "Open Sans", -apple-system, BlinkMacSystemFont, "Helvetica Neue",
    Helvetica, Segoe UI, Arial, Roboto, "PingFang SC", "miui",
    "Hiragino Sans GB", "Microsoft Yahei", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  max-width: 10rem;
  margin: 0 auto;
  .block {
    .block-cell-title {
      font-weight: bold;
      font-size: 32px;
    }
  }
}
.block-title {
  height: 96px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 32px;
  font-weight: bold;
  color: #333;
  width: 100%;
  background: #fff;
  padding-left: 24px;
}
.none-data {
  line-height: 150px;
  text-align: center;
  font-size: 28px;
  color: #666;
}
.tag {
  padding: 0 8px;
  height: 22px;
  text-align: center;
  line-height: 20px;
  font-size: 12px;
  border-radius: 2px;
  background-color: #0000000a;
  border: 1px solid #00000026;
  color: #000000a6;
  &.red {
    background-color: #f7dfe2;
    border: 1px solid #f8808e;
    color: #ee0a24;
  }
  &.blue {
    background-color: #e6f7ff;
    border: 1px solid #91d5ff;
    color: #1890ff;
  }
  &.green {
    background-color: #f6ffed;
    border: 1px solid #b7eb8f;
    color: #52c41a;
  }
  &.orange {
    background-color: #fffbe6;
    border: 1px solid #ffe58f;
    color: #faad14;
  }
  &.gray {
    background-color: #0000000a;
    border: 1px solid #00000026;
    color: #000000a6;
  }
}

</style>
