import Vue from "vue";
import VueRouter from "vue-router";
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/home"
  },
  {
    path: '*',
    name: '404',
    meta: {
      title: '',
    },
    component: () => import(/* webpackChunkName: "404" */ '@/views/404'),
  },
  {
    path: "/login",
    name: "Login",
    meta: {
      title: "登录"
    },
    component: () => import("@/views/login/Index.vue")
  },
  {
    path: "/home",
    name: "Home",
    meta: {
      title: "首页"
    },
    component: () => import("@/views/home/Index.vue")
  },
  {
    path: "/notice",
    name: "Notice",
    meta: {
      title: "催收提醒"
    },
    component: () => import("@/views/home/notice")
  },
  {
    path: "/caseList",
    name: "CaseList",
    meta: {
      title: "案件明细"
    },
    component: () => import("@/views/Collection/CaseList.vue")
  },
  {
    path: "/caseDetails",
    name: "CaseDetails",
    meta: {
      title: "案件详情"
    },
    component: () => import("@/views/Collection/CaseDetails.vue")
  },
  {
    path: "/urgeChargeRecord",
    name: "UrgeChargeRecord",
    meta: {
      title: "催收记录"
    },
    component: () => import("@/views/Collection/UrgeChargeRecord.vue")
  },
  {
    path: "/newReminder",
    name: "NewReminder",
    meta: {
      title: "新增催记"
    },
    component: () => import("@/views/Collection/NewReminder.vue")
  },
  {
    path: "/overdueCustomer",
    name: "OverdueCustomer",
    meta: {
      title: "逾期客户"
    },
    component: () => import("@/views/overdueCustomer")
  },
  {
    path: "/urgeChargeRecordManage",
    name: "UrgeChargeRecordManage",
    meta: {
      title: "催收记录"
    },
    component: () => import("@/views/urgeChargeRecordManage")
  },
  {
    path: "/urgeChargeRecordDetail",
    name: "UrgeChargeRecordDetail",
    meta: {
      title: "催收详情"
    },
    component: () => import("@/views/urgeChargeRecordManage/detail.vue")
  },
  {
    path: "/chooseCustomer",
    name: "ChooseCustomer",
    meta: {
      title: "选择客户"
    },
    component: () => import("@/views/urgeChargeRecordManage/chooseCustomer.vue")
  },
  {
    path: "/applyTrial",
    name: "ApplyTrial",
    meta: {
      title: "申请试用"
    },
    component: () => import(/* webpackChunkName: "applyTrial" */ '@/views/layout.vue'),    
    children: [
      {
        path: '',
        name: 'ApplyTrialIndex',
        meta: {
          title: '申请试用',
        },
        component: () => import(/* webpackChunkName: "applyTrial" */ '@/views/applyTrial'),
      },
      {
        path: 'success',
        name: 'ApplyTrialSuccess',
        meta: {
          title: '申请成功',
        },
        component: () => import(/* webpackChunkName: "applyTrial" */ '@/views/applyTrial/success'),
      },

      //
    ]
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

// const whiteList = ["/login"];
// const isDev = process.env.NODE_ENV === "development";

// import store from "@/store";
// import { getToken } from "@/utils/app";
// import { Toast } from "vant";

router.beforeEach(async (to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  } else {
    document.title = "H5";
  }
  // if (whiteList.includes(to.path) || isDev) {
    next();
  // } else {
  //   const userInfo = store.state.userInfo;
  //   const token = getToken();
  //   const isLogin = !!(userInfo && token);
  //   next();
  //   if (!isLogin) {
  //     next("/login");
  //     Toast.fail({
  //       duration: 1500,
  //       message: "登录信息已过期，请重新登录"
  //     });
  //   }
  // }
});

export default router;
