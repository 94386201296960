import Vue from "vue";
import Vuex from "vuex";
import persistedState from "vuex-persistedstate";

import enums from './modules/enums'

Vue.use(Vuex);
const state = {
  wxUserInfo: null,
  userInfo: {},
  userAccessToken: {}, // 用于获取用户信息的token
  openId: null
};
const getters = {
  getUserInfo(state) {
    return state.userInfo;
  }
};
const mutations = {
  SET_USERINFO: (state, data) => {
    const d = data || {};
    state.userInfo = d;
  }
};
const actions = {
  setUserInfo: ({ commit }, data) => {
    commit("SET_USERINFO", data);
  }
};
export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
  modules: {
    enums,
  },
  plugins: [persistedState()]
});
