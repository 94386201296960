import { get, post, /* put, del */ } from "@/utils/https";
import qs from "qs";

// 催收统计-v2
export const collectionReminderStatisticsPost = async (data) => {
  const res = await post(`/api/v4/merchant/app/leased/dashboard/collection/reminder/statistics`, data);
  return res?.data || [];
};

// 获取催收提醒
export const dashboardCollectionReminder = async () => {
  const res = await get(`/api/v4/merchant/app/leased/dashboard/collection/reminder`);
  return res?.data || [];
};

// 创建推荐客户
export const recommendCustomCreatePost = async (data) => {
  await post(`/api/v4/merchant/app/basic/recommend/custom/recommend/custom/create`, data);
};

// 发送验证码
export const recommendCustomSendSmsCode = async (mobile) => {
  await get(`/api/v4/merchant/app/basic/recommend/custom/sendSmsCode/${mobile}`)
}

// 选择客户
export const customerCaseDetailsPagePost = async (data) => {
  const res = await post(`/api/v4/merchant/app/collection/customerCaseDetailsPage`, data);
  return res?.data || {};
};

// 逾期等级
export const overdueLevelEnum = async (data) => {
  const res = await get(`/api/v4/merchant/app/basic/data/overdueLevelEnum`, data);
  return res?.data || [];
};

// 新增催收记录
export const collectionAddCollectionPost = async (data) => {
  await post(`/api/v4/merchant/app/collection/addCollection`, data);
};

// 催收分页
export const collectionFindPagePost = async (data) => {
  const res = await post(`/api/v4/merchant/app/collection/findPage`, data);
  return res?.data || {};
};

// 获取联系人关系枚举
export const getInstancyRelativeEnum = async (data) => {
  const res = await get(`/api/v4/merchant/app/basic/data/getInstancyRelativeEnum`, data);
  return res?.data || [];
};

// 催收结果树
export const getTreeByParentKey = async (data) => {
  const res = await get(`/api/v4/merchant/app/collection/getTreeByParentKey`, data);
  return res?.data || [];
};

// 登出
export const logoutToken = async () => {
  await post(`/logout/token`);
};

// 登录入参
export const SignLoginParams = {
  scope: "userProfile",
  client_id: process.env.VUE_APP_CLIENT_ID,
  client_secret: process.env.VUE_APP_CLIENT_SECRET
};
// 登录入参校验
export const SignLoginValidate = async (data) => {
  if (!data) throw "请完善登录信息";
  if (!data.username) throw "请填输入手机号";
  if (data.usePassword) {
    if (!data.password) throw "请输入密码";
    data.grant_type = 'password';
  } else {
    if (!data.smsCode) throw "请输入手机验证码";
  }
  if (!data.agree) throw "请同意平台服务协议";
  return data;
};

// 登录
export const login = async (data) => {
  const _data = { ...SignLoginParams, ...data };
  await SignLoginValidate(_data);

  let authToken = window.btoa(
    `${_data.client_id}:${_data.client_secret}`
  );
  delete _data.agree;
  const urlParms = {
    ..._data,
    scope: "userProfile"
    // client_id: "TxcKVn3mQlQE9WbyETwB0BAr",
    // client_secret: "lh8mgD2atZbBu4oHn1tcOoC7llzarzW8"
  };
  return post(
    data.usePassword ? `login/token` : `login/sms/token`,
    qs.stringify(urlParms),
    {},
    {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Basic ${authToken}`,
      platform: "FINANCE_MERCHANT_H5"
    }
  );
};
// 登录验证码
export function smsLgcode(mobile) {
  return get(`/api/v4/merchant/app/merchant/loginSmsCode/${mobile}`);
}

// 催收统计
export const statistics = (data = {}) => {
  return post(`/api/v4/merchant/app/leased/dashboard/statistics`, data);
};

// 案件明细分页
export const caseDetailsPage = async (data) => {
  const res = await post(
    `/api/v4/merchant/app/leased/dashboard/caseDetails`,
    data
  );
  return res?.data || {};
};

// 催收-还款意愿枚举
export const getRepaymentPossibilityEnum = async (data) => {
  const res = await get(
    `/api/v4/merchant/app/collection/getRepaymentPossibilityEnum`,
    data
  );
  return res?.data || [];
};
// 催收-诉讼建议枚举
export const getLegalSuggestionEnum = async (data) => {
  const res = await get(
    `/api/v4/merchant/app/collection/getLegalSuggestionEnum`,
    data
  );
  return res?.data || [];
};
