import {
  getTreeByParentKey,
  getInstancyRelativeEnum,
  getRepaymentPossibilityEnum,
  getLegalSuggestionEnum,
} from "@/api";

export default {
  namespaced: true,
  state() {
    return {
      relationshipList: [], // 关系枚举
      resultList: [], // 催收结果树
      repaymentPossibilityEnum: [],
      legalSuggestionEnum: [],
    };
  },
  getters: {
    resultListFilterChilds({ resultList: data }) {
      // 去掉空childs
      const recur = (arr) => {
        arr.forEach((item) => {
          if (item.childs?.length) {
            item.childs = recur(item.childs);
          } else {
            delete item.childs;
          }
        });
        return arr;
      };
      return recur(data);
    },

    //
  },
  mutations: {
    update(state, { name, value }) {
      state[name] = value;
    },

    //
  },
  actions: {
    async getCollectionResultList({ commit }) {
      const data = await getTreeByParentKey();
      commit("update", { name: "resultList", value: data });
    },

    async getRelationshipList({ commit }) {
      const data = await getInstancyRelativeEnum();
      commit("update", { name: "relationshipList", value: data });
    },
    async getRepaymentPossibilityEnum({ commit }) {
      const data = await getRepaymentPossibilityEnum();
      commit("update", { name: "repaymentPossibilityEnum", value: data });
    },
    async getLegalSuggestionEnum({ commit }) {
      const data = await getLegalSuggestionEnum();
      commit("update", { name: "legalSuggestionEnum", value: data });
    },

    //
  },
};
