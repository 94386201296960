import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "lib-flexible/flexible";
import VConsole from "vconsole";
import "windi.css";

if (process.env.NODE_ENV != "production") new VConsole();

import Vant from "vant";
import "vant/lib/index.css";
Vue.use(Vant);
import { Toast, Dialog, Notify, ImagePreview } from "vant";

Vue.prototype.$toast = Toast;
Vue.prototype.$Dialog = Dialog;
Vue.prototype.$notify = Notify;
Vue.prototype.$ImagePreview = ImagePreview;

Vue.prototype.$errMsg = (error) => {
  Toast.clear(true);
  return Toast.fail(error.message || error.msg || error || "未知错误");
};
Vue.prototype.window = window;
Vue.config.productionTip = false;
new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount("#app");
